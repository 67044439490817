import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-scroll";
import { Builder } from '@builder.io/gatsby';

import { Title, Button, Section, Box, Text } from "../../components/Core";

import { device } from "../../utils";
import imgL from "../../assets/jane/jane_portrait.png"

const StyledSection = styled.div`
min-height: 100vh;
background-color: #C3E9D8;
padding-top: 4rem;
padding-bottom: 3rem;

`

const StyledCol = styled(Col)`
`

const ImgRight = styled.img`
  max-width: 100%;
`;

const Meet = styled.span`
  color: #DCB4B5;
`;

const Your = styled.span`
color: #8E6086;
`;

const Mind = styled.span`
  color: #72989F;
`;



const Hero = ({data}) => {
  return (
    <>
      {/* <!-- Hero Area --> */}

      <StyledSection hero className="position-relative">
        <Container>
          <Row className="align-items-center">
            <Col lg="8" className="mb-5 mb-lg-0">
                <Text
                  variant="tag"
                  mb={4}
                  className="text-uppercase"
                  color="heading"
                  mb="40px"
                >
                </Text>
                <Title variant="hero">
                {data.title.map((item, index) => {
                  return (
                    <span style={{ color: item.color }}>{item.word.text} </span>
                  )
                })}

                </Title>
                <Text pt={3}>

                  {data.about_hero_copy.text}

                </Text>
            </Col>
            <StyledCol lg="4" md="8" sm="9">
              <div className="text-center text-lg-right position-relative">
                <div className="img-main">
                  <ImgRight src={data.about_hero_image.url} alt="" />
                </div>
              </div>
            </StyledCol>
          </Row>
        </Container>
      </StyledSection>
    </>
  );
};


export default Hero;
