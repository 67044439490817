import React, { useContext, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import GlobalContext from "../../context/GlobalContext";
import { Section, Title, Button, Text } from "../../components/Core";
import Availability from "../../components/Availability";

import styled from "styled-components";
import CTABG from "../../assets/jane/opacity.jpg";
import educationSVG from "../../assets/jane/education.svg";
import { RichText } from 'prismic-reactjs'



const StyledSection = styled(Section)`
padding-top: unset;
padding-bottom: unset;
.child {
  min-height: 100vh;
  scroll-snap-align: start;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}



div.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #90608A;
  padding: 0px;
  font-size: 20px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

}

.bg_one{
  background-color: #729AA1;
}
.bg_two{
  background-color: #DEB6B7;
}
.bg_three{
  background-color: #3B5667;
}

.bg_four{
  background-color: #C7ECDB !important;
}

.copy_cta{
  text-align: center;
  display: block;
}

`

const StyledCol = styled(Col)`
scroll-snap-type: mandatory;
scroll-snap-points-y: repeat(100vh);
scroll-snap-type: y mandatory;

`

const StyledCTARow = styled(Row)`

min-height: 50vh;
text-align: center;
background-image: url(${CTABG});
background-repeat: no-repeat;
background-size: cover, cover;
background-position: center;


p{

  padding-top: 4rem;
  width: 100%;
  font-size: 2rem;
  font-weight: bold;
}

`


const Education = ({data}) => {

  const gContext = useContext(GlobalContext);


  useEffect(() => {

  }, []);


  return (
    <>
      <StyledSection id='copy' bg='#C3E9D8'>
        <Row className='no-gutters'>
          <Col xl="6">
            <div className="sticky">
              <div class='copy_cta'>
                <Title color='#DDB7B7'>Education</Title>

                

                <Title style={{whiteSpace: "pre-line"}} variant='cardLg' color='#DDB7B7'>

                <div dangerouslySetInnerHTML={{ __html: data.info_block_content.text}}
/>


                </Title>

              </div>
            </div>
          </Col>
          <StyledCol xl="6">
            <section>
              <div id='t1' className='child bg_one'>
                <img style={{width: "50%"}} src={data.info_block_right_image.url}></img>
              </div>
            </section>
          </StyledCol>
        </Row>
      </StyledSection>
    </>
  );
};

export default Education;
