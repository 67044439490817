import React from "react";
import { Element } from "react-scroll";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/landing1/HeroPage";
import Works from "../sections/landing1/Works";
import Contact from "../sections/landing1/Contact";
import Copy from "../sections/landing1/CopyAbout";
import Testimonial from "../sections/landing1/Testimonial";
import Education from "../sections/landing1/Education";
import CTA from "../sections/landing1/CTARow";
import SEO from "../components/seo";

const IndexPage = ({data}) => {


  return (
    <>

      <PageWrapper>
        <SEO title={data.prismicAboutPage.data.meta_title.text} description={data.prismicAboutPage.data.meta_description.text}/>

        <Hero data={data.prismicAboutPage.data}/>
        <Testimonial data={data.prismicAboutPage.data}/>
        <Education data={data.prismicAboutPage.data}/>
        {data.prismicAboutPage.data.show_cta === true &&
        <CTA data={data.prismicCta.data}/>
        }     
       </PageWrapper>
    </>
  );
};

export const query = graphql`
query AboutQuery {
  prismicAboutPage {
    data {
      about_hero_copy {
        text
      }
      about_hero_image {
        url
      }
      info_block_content {
        text
        html
      }
      info_block_right_image {
        url
      }
      info_block_title {
        text
      }
      reviews {
        review_name {
          text
        }
        review_text {
          text
          html
        }
      }
      show_cta
      title {
        color
        word {
          text
        }
      }
      meta_description {
        text
      }
      meta_title {
        text
      }
    }
  }
  prismicCta(internal: {}) {
    id
    data {
      copy {
        text
      }
      cta_button {
        text
      }
      cta_image {
        url
      }
    }
  }
}

`

export default IndexPage;
