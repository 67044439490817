import React, { useContext, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import GlobalContext from "../../context/GlobalContext";
import { Section, Title, Button, Text, Box } from "../../components/Core";
import Availability from "../../components/Availability";

import styled from "styled-components";
import CTABG from "../../assets/jane/opacity.jpg";
import educationSVG from "../../assets/jane/education.svg";




const StyledSection = styled(Section)`
padding-top: unset;
padding-bottom: unset;
.child {
  min-height: 100vh;
  scroll-snap-align: start;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}



div.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #90608A;
  padding: 0px;
  font-size: 20px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

}

.bg_one{
  background-color: #729AA1;
}
.bg_two{
  background-color: #DEB6B7;
}
.bg_three{
  background-color: #3B5667;
}

.bg_four{
  background-color: #C7ECDB !important;
}

.copy_cta{
  h2{
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  div{
    text-align: left;
    padding: 3rem;
  }
  text-align: center;
  display: block;
}



`

const StyledCol = styled(Col)`
scroll-snap-type: mandatory;
scroll-snap-points-y: repeat(100vh);
scroll-snap-type: y mandatory;

`

const StyledCTARow = styled(Row)`

min-height: 50vh;
text-align: center;
background-image: url(${CTABG});
background-repeat: no-repeat;
background-size: cover, cover;
background-position: center;


p{

  padding-top: 4rem;
  width: 100%;
  font-size: 2rem;
  font-weight: bold;
}

`
const StyledBox = styled(Box)`

margin-top: 2rem;

`


const ContentCard = ({
  className,
  image,
  name,
  company,
  children,
  ...rest
}) => (
  <StyledBox p="25px" className={`${className}`} {...rest}>
    <Box className={`d-flex justify-content-between`}>

      <div className="flex-grow-1">
        <Title variant="card" mb={0} color='white'>
          {name}
        </Title>
        <Text mt={2} color="white">
          {children}
        </Text>

      </div>
    </Box>
  </StyledBox>
)


const Copy = () => {

  const gContext = useContext(GlobalContext);

  useEffect(() => {

  }, []);


  return (
    <>
      <StyledSection id='copy' bg='#90608A'>
        <div>
          <div className='copy_cta'>
            <Title color='#C7ECDB'>Outcomes</Title>

          </div>
        </div>
      </StyledSection>
      <StyledSection id='copy' bg='#C3E9D8'>
        <Row className='no-gutters'>
          <Col xl="6">
            <div className="sticky">
              <div class='copy_cta'>
                <Title color='#DDB7B7'>Education</Title>
              </div>
            </div>
          </Col>
          <StyledCol xl="6">
            <div id='t1' className='child bg_one'>
              <Title variant='' color='#C7ECDB'>Bachelor of Health Science (Homeopathy)
Advanced Diploma Homeopathy<br />
Certificte IV Kinesiology<br />
Diploma of Kinesiology<br />
Counselling Kinesiology<br />
Integrated Healing Dip<br />
Certified LifeLine Technique® Practitioner<br />
Intuitive Meditation Kinesiology Practitioner<br />
              </Title>
            </div>

          </StyledCol>
        </Row>
      </StyledSection>

    </>
  );
};

export default Copy;
